import { Image, Space, SpinLoading } from 'antd-mobile';
import defaultImg from 'assets/images/default-image.png';
import classNames from 'classnames';
import Button from 'components/basic/Button';
import JobStatus from 'components/compound/JobStatus';
import Rate from 'components/compound/Rate';
import ApplyStatus from 'components/compound/jobInfo/ApplyStatus';
import BaseInfo from 'components/compound/jobInfo/BaseInfo';
import RecruitmentInfo from 'components/compound/jobInfo/RecruitmentInfo';
import loginUser from 'data/users';
import { useCustomNavigate, useMediaQuery } from 'hooks/hooks';
import useJobInfo from 'hooks/jonInfo';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import api from 'services/index';
import { isInApp, jobInfoButtonStatus } from 'utils/help';

// import { replaceCurrentURLAndReflush } from 'utils/utils';

const JobDetailPage: React.FC = () => {
  const navigate = useCustomNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery();
  const { favoriteApi } = api;

  const { jobId } = useParams<{ jobId: string }>();
  useEffect(() => {
    // 如果是已登录状态, 用 sessionStorage 的方式标注操作了 job, 以此更新 jobList
    if (loginUser.isLogin()) {
      sessionStorage.setItem('wow_app_job_id', jobId ?? '');
    }
  }, [location.pathname]);

  const [{ isLoading, jobInfo, jobStatus }, { getJobInfo }] = useJobInfo();

  useEffect(() => {
    window.wowApp?.uwnc?.callNative?.(
      'NaJobDetailEntry',
      { url: window.location.href },
      () => {},
    );
    getJobInfo(parseInt(jobId ?? ''));

    // const bottomtabactiveHandle = (e: Event) => {
    //   const {
    //     detail: { activeIndex },
    //   } = e as CustomEvent<{ activeIndex: number }>;
    //   const { origin, search } = window.location;
    //   if (activeIndex === 1) replaceCurrentURLAndReflush(`${origin}${search}`);
    //   else if (activeIndex === 2)
    //     replaceCurrentURLAndReflush(`${origin}/collected-jobs${search}`);
    // };
    // window.addEventListener('bottomtabactive', bottomtabactiveHandle);

    // return () => {
    //   // clear event side-effect
    //   window.removeEventListener('bottomtabactive', bottomtabactiveHandle);
    // };
  }, []);

  const refreshJob = () => {
    getJobInfo(parseInt(jobId ?? ''));
  };

  if (!jobInfo) {
    return (
      <div className="wd-full text-center">
        <Space style={{ textAlign: 'center', marginTop: 10 }}>
          <SpinLoading color="#ff8b45" />
        </Space>
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const save_favorite = async (workId: number) => {
    const result = await favoriteApi.saveFavorite({
      workId,
    });
    const { code, message } = result || {};
    if (+code === 200) {
      getJobInfo(workId);
    } else {
      window.wowApp.toast.show({ content: message });
    }
  };

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const delete_favorite = async (workId: number) => {
    const result = await favoriteApi.deleteFavorite({
      workId,
    });
    const { code, message } = result || {};
    if (+code === 200) {
      getJobInfo(workId);
    } else {
      window.wowApp.toast.show({ content: message });
    }
  };

  const favoriteStatus = (is_favorite: boolean, jobId: number) => {
    // 未登录
    if (loginUser.isLogin()) {
      if (is_favorite) {
        delete_favorite(jobId ?? 0);
      } else {
        save_favorite(jobId ?? 0);
      }
    } else {
      // 登录
      if (isInApp()) {
        window.wowApp.uwnc.callNative('NaGoToLogin', {}, () => {});
      } else {
        navigate('/login');
      }
    }
  };

  const ImgError = (
    <>
      <img style={{ width: '100%', objectFit: 'cover' }} src={defaultImg} />{' '}
    </>
  );

  return (
    <div className="job-info">
      <div className="job-info-container">
        <div
          className={classNames(
            `job-info`,
            isInApp() ? 'job-info-padding-bottom' : '',
          )}
        >
          <div className="job-info-basic mb-5-x">
            <div className="job-info-img mb-5-x">
              <div className="job-item-view-compound flex">
                <div className="job-item-view-left">
                  <Image
                    src={jobInfo?.info.thumbnail_path}
                    fit="cover"
                    placeholder={ImgError}
                    fallback={ImgError}
                    lazy
                  />
                </div>
                <div className="job-item-view-right font-md font-weight-700 flex-center pr-10 pl-10">
                  <span>{jobInfo?.info.title}</span>
                </div>
              </div>
            </div>
          </div>

          {loginUser.isLogin() && jobStatus && <ApplyStatus data={jobStatus} />}
          {/* {jobStatus && <ApplyStatus data={jobStatus} />} */}
          {jobInfo?.info.baseInfo && (
            <BaseInfo info={jobInfo?.info.baseInfo} worID={jobId ?? ''} />
          )}
          {jobInfo?.info.recruitmentInfo && (
            <RecruitmentInfo info={jobInfo.info.recruitmentInfo} />
          )}
        </div>
      </div>
      <div className="job-info-footer-btn">
        <div className="wd-full flex-center">
          {/* 收藏 */}
          {!isMobile ? (
            // {/* ============= */}
            <div
              className={
                jobInfoButtonStatus(jobInfo) === 0 ||
                jobInfoButtonStatus(jobInfo) === 3 ||
                jobInfoButtonStatus(jobInfo) === 6
                  ? 'mt-5-x'
                  : ''
              }
            >
              <Button
                className="job-info-rate-pc cursor-pointer "
                disabled={isLoading}
                onClick={() => {
                  favoriteStatus(jobInfo.info.is_favorite, jobInfo.info.id);
                }}
              >
                <Rate rate={jobInfo?.info.is_favorite} />
                <span className="font-weight-700 text-gray-darker">
                  {t('button.favorites')}
                </span>
              </Button>
            </div>
          ) : (
            //  {/* mobile============= */}
            <Rate
              className="job-info-rate-sp cursor-pointer font-weight-700"
              onClick={() => {
                if (!isLoading) {
                  favoriteStatus(jobInfo.info.is_favorite, jobInfo.info.id);
                }
              }}
              rate={jobInfo?.info.is_favorite}
            />
          )}
          {/* end 收藏 */}

          {/* 0: 未登录 1: 工作失效 2: 工作应募时间失效(过期) 3:未认证  4:已认证但未应募 5:已认证且已应募 */}
          <JobStatus
            status={jobInfoButtonStatus(jobInfo)}
            jobId={jobInfo?.info.id ?? 0}
            refreshJob={refreshJob}
            apply_info={jobInfo.info.apply_info}
          />
        </div>
      </div>
    </div>
  );
};
export default JobDetailPage;
