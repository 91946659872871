/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { type IBaseInfo } from 'structures/jobDetailItem';
import { dateConversion } from 'utils/help';

import { Info } from './Info';
import { SubTitle } from './SubTitle';

interface BaseInfoProps {
  info: IBaseInfo;
  worID: string;
}

const BaseInfo: React.FC<BaseInfoProps> = ({ info, worID }) => {
  const { t } = useTranslation();
  return (
    <Info text={t('jobInfo.baseInfo')}>
      {info.valid_to && (
        // eslint-disable-next-line react/jsx-no-comment-textnodes
        <SubTitle title={t(info.deadline_title ?? '')} className="mb-5 ">
          <div className="text-left">{dateConversion(info.valid_to) + ''}</div>
        </SubTitle>
      )}
      <SubTitle title={t('workID')} className="mb-5 mt-5-x">
        <div className="mr-5">{worID}</div>
      </SubTitle>

      <SubTitle title={t(info.occupation_title ?? '')} className="mb-5 mt-5-x">
        {info.professions?.map((obj, index) => {
          return (
            <div className="mr-5" key={index}>
              {obj.profession_name}
            </div>
          );
        })}
      </SubTitle>

      <SubTitle title={t(info.location_title ?? '')} className="mb-5 mt-5-x">
        <div className="text-left">{info.work_location ?? ''} </div>
      </SubTitle>

      <SubTitle title={t(info.salary_title ?? '')} className="mt-5-x">
        <div className="mb-10 mt-10">
          {info.salary_types?.map((obj, index) => {
            return (
              <div className="mr-5" key={index}>
                {obj.salary_type_name}
              </div>
            );
          })}
        </div>
      </SubTitle>

      <SubTitle title={t(info.working_hours_title ?? '')} className="mt-5-x">
        <div className="text-wrap text-no-wrap mt-10">
          {info.working_hour_detail}
        </div>
      </SubTitle>

      <SubTitle title={t(info.status_title ?? '')} className="mt-5-x">
        {info.employment_statuses?.map((obj, index) => {
          return (
            <div key={index} className="mt-10 mr-5">
              {obj.employment_status_name}
            </div>
          );
        })}
      </SubTitle>
      <SubTitle title={t(info.work_visa_title ?? '')} className="mt-5-x">
        {info.visas?.map((obj, index) => {
          return (
            <div key={index} className="mt-10 mr-5">
              {obj.visa_name}
            </div>
          );
        })}
      </SubTitle>
    </Info>
  );
};
export default BaseInfo;
